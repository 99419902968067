import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Header from '../components/header';
import Footer from '../components/footer';
import SEO from '../components/seo';
import HeroImage from '../images/banner-new.jpg';
import userServices from '../services/userServices';
import DisplayAccordion from '../components/DisplayAccordion';
import { device } from '../constants/device';

const keywords = [
  'National Freight Index',
  'road',
  'Trucking',
  'Prices Rates',
  'India',
  'Spot Rate',
  'Container',
  'Open',
  'Trailer',
  'rupees per ton km',
  'road trends'
];

const MethodologyBackground = styled.div`
  background: url(${HeroImage}) no-repeat scroll center;
  background-size: cover;
  height: 165px;
`;

const MainMethodology = styled.div`
  min-height: 400px;
  height: auto;
  max-width: 1230px;
  margin: -70px auto 50px;
  padding: 30px 48px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  @media ${device.tablet} {
    padding: 20px;
  }
`;

const Heading = styled.div`
  font-size: 28px;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export default class methodology extends Component {
  state = {
    isUserLoggedIn: false,
    userMobile: null
  };

  async componentWillMount() {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('sessionToken')) {
        this.setState({ isXhrInProgress: true });
        let checkUser = await userServices.authenticateUser();
        this.setState({ isXhrInProgress: false });
        if (checkUser && checkUser.mobile && checkUser.mobile !== '') {
          window.localStorage.setItem('userMobile', checkUser.mobile);
          this.setState({ userMobile: checkUser.mobile, isUserLoggedIn: true });
        } else {
          window.localStorage.removeItem('sessionToken');
          window.localStorage.removeItem('userMobile');
        }
      }
    }
  }

  render() {
    const { isUserLoggedIn, userMobile } = this.state;
    return (
      <div>
        <SEO keywords={keywords} />
        <Header isUserLoggedIn={isUserLoggedIn} userMobile={userMobile} />
        <MethodologyBackground />
        <div style={{ padding: '0 15px' }}>
          <MainMethodology>
            <Heading>Frequently asked questions</Heading>
            <DisplayAccordion data={this.props.data} />
          </MainMethodology>
        </div>
        <Footer />
      </div>
    );
  }
}

methodology.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query {
    allContentfulFaqs {
      edges {
        node {
          question
          questionBody {
            childMarkdownRemark {
              html
            }
          }
          order
        }
      }
    }
  }
`;
