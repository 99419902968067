import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iffun from '@bit/rivigo.ui.utils.iffun';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
  AccordionItemState
} from 'react-accessible-accordion';
import { ChevronIcon } from '../custom-icons';
import { device } from '../constants/device';
import userServices from '../services/userServices';
import HTMLContent from '../components/Content';

const Question = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 15px;
  color: #2a2a2a;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-right: 15px;
  }
  svg {
    transform: rotate(90deg);
  }
  svg.active {
    transform: rotate(-90deg);
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const Rectangle = styled.div`
  height: 1px;
  border-bottom: 1px solid #d8d8d8;
`;

const QuestionBody = styled.div`
  p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.6;
    color: #666666;
    &:last-child {
      margin-bottom: 20px;
    }
  }
  a {
    color:#e3383a
  }
  ul {
    margin: 15px 0 15px 30px;
    &:last-child {
      margin-bottom: 20px;
    }
    li {
      margin-bottom: 5px;
      line-height: 1.5;
      list-style-type: disc;
      color: #666666;
    }
  }
`;

function myCompFunction(a, b) {
  if (a.node.order < b.node.order) {
    return -1;
  } else {
    return 1;
  }
}

export default class DisplayAccordion extends Component {
  handleQuestionBodyClick = (faq) => {
    userServices.sendAnalyticsEvents({
      category: 'FAQ',
      action: 'Clicks',
      label: faq.question
    });
  };
  render() {
    const data = this.props.data;
    const sortedData = data.allContentfulFaqs.edges.sort((a, b) => myCompFunction(a, b));
    return (
      <Accordion allowMultipleExpanded allowZeroExpanded>
        {sortedData.map((faq, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Question onClick={() => this.handleQuestionBodyClick(faq)}>
                    <p>{faq.node.question}</p>
                    <AccordionItemState>
                      {({ expanded }) => <ChevronIcon className={expanded ? 'active' : ''} />}
                    </AccordionItemState>
                  </Question>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <QuestionBody>
                  <HTMLContent className='content' content={faq.node.questionBody.childMarkdownRemark.html} />
                </QuestionBody>
              </AccordionItemPanel>

              {iffun(index !== sortedData.length - 1, <Rectangle />, null)}
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  }
}

DisplayAccordion.propTypes = {
  data: PropTypes.object
};
